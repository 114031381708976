<template>
    <b-overlay :show="loading" opacity="0.2">
        <div class="row">
            <div class="col-12">
                <h2 class="mb-2">{{$safe(request,'CariName') || '---'}} | Teklif No: {{$safe(request,'OfferCode') || '---'}}</h2>
            </div>
            <div class="col-lg-2">
                <div class="row">
                    <div class="col-12 mb-1">
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Son Cevap Tarihi</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'LastAnswerDateStr') || '-'}}</h4>
                        </b-card>
                    </div>
                    <div class="col-12 mb-1" >
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Sözleşme Başlangıç Tarihi</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'OfferAgreementStartDate') || '-'}}</h4>
                        </b-card>
                    </div>
                    <div class="col-12 mb-1">
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Sözleşme Bitiş Tarihi</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'OfferAgreementEndDate') || '-'}}</h4>
                        </b-card>
                    </div>
                    <div class="col-12 mb-1" v-if="$safe(request,'InflationAffectValueActive')">
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Enflasyon Etki Oranı (%)</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'InflationAffectValue') || '-'}}%</h4>
                        </b-card>
                    </div>
                    <div class="col-12 mb-1" v-if="$safe(request,'FuelAffectValueActive')">
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Yakıt Etki Oranı (%)</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'FuelAffectValue') || '-'}}%</h4>
                        </b-card>
                    </div>
                    <div class="col-12" v-if="$safe(request,'FuelAffectRateValueActive')">
                        <b-card body-class="p-1" class="mb-0">
                            <h5 class="text-muted">Yakıt Fiyatı Artış/Azalış Oranı (%)</h5>
                            <h4 class="mb-0 font-weight-bolder">{{$safe(request,'FuelAffectRateValue') || '-'}}%</h4>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <b-card body-class="p-1" class="mb-0">
                    <div class="alert" :class="statusColor" v-if="$safe(request,'Status') != null">
                        <div class="lightinig alert-body">
                            {{ $safe(request,'StatusStr') }}
                        </div>
                    </div>
                    <div v-if="request.OfferDocumentUrl != ''" class="row d-flex justify-content-end mb-1">
                        <div class="col-auto mb-1 mb-md-0">
                            <button class="btn btn-success text-nowrap waves-effect" v-on:click="downloadExcel">
                                <feather-icon icon="DownloadIcon" size="16" ></feather-icon>
                                Teklif Kıyas İndir
                            </button>
                        </div>
                        <div class="col-auto mb-1 mb-md-0">
                            <button class="btn btn-primary text-nowrap waves-effect" v-on:click="downloadOfferDocument">
                                <feather-icon icon="DownloadIcon" size="16" ></feather-icon>
                                Teklif Şartnamesi İndir
                            </button>
                        </div>
                    </div>
                    <h3>Teklif Rotaları</h3>
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th class="w-10 align-middle">
                                    <div class="d-flex align-items-center">
                                        <b-form-checkbox v-model="all" v-on:change="checkAll" :disabled="$safe(request,'Status') != 1"></b-form-checkbox>
                                        Onaylandı
                                    </div>
                                </th>
                                <th class="w-13 align-middle">Başlangıç Yeri</th>
                                <th class="w-13 align-middle">Bitiş Yeri</th>
                                <th class="w-13 align-middle">Stok</th>
                                <th class="w-12 align-middle">Araç Tipi</th>
                                <th class="w-13 align-middle">Taşıma Durumu</th>
                                <th class="w-13 align-middle">Müşteri Teklif Fiyatı</th>
                                <th class="w-13 align-middle">{{($safe(request,'Status') == 3 || $safe(request,'Status') == 4) ? 'Anlaşılan Fiyat (₺)' : 'Anlaşılacak Fiyat (₺)' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="(request.OfferItems || []).length > 0">
                                <template v-for="(oi,i) in request.OfferItems">
                                    <tr :key="i">
                                        <td>
                                            <b-form-checkbox v-model="oi.Accepted" :disabled="$safe(request,'Status') != 1" v-on:input="controlAll"></b-form-checkbox>
                                        </td>
                                        <td>{{$safe(oi,'StartTransferRegionName') || '-'}}</td>
                                        <td>{{$safe(oi,'EndTransferRegionName') || '-'}}</td>
                                        <td>{{$safe(oi,'MaterialName') || '-'}}</td>
                                        <td>{{$safe(oi,'VehicleTypeName') || '-'}}</td>
                                        <td>{{$safe(oi,'VehicleTransportStatusName') || '-'}}</td>
                                        <td class="text-right font-weight-bolder">
                                            {{$safe(oi,'CarrierPriceStr') || '-'}}
                                        </td>
                                        <td v-if="$safe(request,'Status') == 1">
                                            <v-currency-input input-class="text-right form-control-sm" :disabled="!oi.Accepted" :value="oi.Price" v-on:update-value="currencyAmount => priceChange(oi,currencyAmount)"></v-currency-input>
                                        </td>
                                        <td class="text-right font-weight-bolder" v-else>
                                            {{$safe(oi,'PriceStr') || '-'}}
                                        </td>
                                    </tr>
                                    <tr v-if="oi.Changed && oi.Accepted" :key="'d'+i">
                                        <td colspan="100" class="p-75">
                                            <label class="form-label">Açıklama</label>
                                            <textarea class="form-control" placeholder="Açıklama" v-model="oi.ManualPriceUpdateDescription"></textarea>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <tr v-else>
                                <td colspan="5" class="text-center">Bir teklif rotası bulunmamaktadır.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <v-executer :loading="declining" class="mt-1" :action="decline" variant="danger" v-if="$safe(request,'Status') == 0 || $safe(request,'Status') == 1" text="İptal Et"></v-executer>
                        </div>
                        <div class="col-auto">
                            <v-executer :loading="updating" class="mt-1" :action="update" v-if="$safe(request,'Status') == 1" text="Güncelle"></v-executer>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import { OfferApi } from '@/api/offer'
import { BOverlay, BCard, BFormCheckbox } from 'bootstrap-vue'
import VCurrencyInput from '@/components/VCurrencyInput.vue'
import Additional from '@/utilities/additional'
import VFileUploader from '@/components/v-file-uploader/VFileUploader.vue'

export default {
    components:{
        VFileUploader,
        BOverlay,
        BCard,
        VCurrencyInput,
        BFormCheckbox
    },
    data(){
        return{
            id:this.$route.params?.id || null,
            request:{},
            loading:true,
            all:true,
            updating:false,
            declining:false,
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        priceChange(oi,amount){
            if(amount != oi.BasePrice){
                oi.Changed = true 
            }else{
                oi.Changed = false
            }
            oi.Price = amount
        },
        checkAll(){
            this.request.OfferItems = this.request.OfferItems?.map(oi=>({
                ...oi,
                Accepted:this.all
            }))
        },
        controlAll(){
            if(this.request.OfferItems.length == this.request.OfferItems.filter(oi=>oi.Accepted).length){
                this.all = true
            }else{
                this.all = false
            }
        },
        get(){
            if(this.id){
                this.loading = true
                OfferApi.Get(this.id).then(res=>{
                    this.request = res.data.Data
                    this.request.OfferItems = this.request.OfferItems?.map(oi=>({
                        ...oi,
                        Changed:false,
                        BasePrice:oi.Price,
                        ManualPriceUpdateDescription:null,
                        Accepted:oi.IsAccepted
                    }))
                    if(this.request.OfferItems.filter(oi=>!oi.Accepted).length > 0){
                        this.all = false
                    }
                    if(res.data.Success){
                        this.loading = false
                    }else{
                        this.$result(res)
                    }
                }).catch(err=>{
                    this.$result(null,err)
                })
            }
        },
        update(){
            this.updating = true
           
            const data = {
                OfferId:this.id,
                File:this.request.File,
                OfferItemPrices:this.request.OfferItems.map(oi=>({
                    ManualPriceUpdateDescription:oi.ManualPriceUpdateDescription,
                    Price:oi.Price,
                    IsAccepted:oi.Accepted,
                    Id:oi.Id
                }))
            }
            OfferApi.OfferCreatorPriceUpdateAndSendCarrierForAgreement(data).then(res=>{
                this.$result(res)
                this.updating = false
                if(res.data.Success){
                    this.get()
                }
            }).catch(err=>{
                this.$result(null,err)
                this.updating = false
            })
        },
        decline(){
            this.declining = true
            OfferApi.Decline(this.id).then(res=>{
                this.$result(res)
                if(res.data.Success){
                    this.get()
                }
                this.declining = false
            }).catch(err=>{
                this.declining = false
                this.$result(null,err)
            })
        },
        downloadOfferDocument(){
            Additional.GetBlobFile(this.request.OfferDocumentUrl).then(c=>{
                const name = 'Teklif Şartnamesi.pdf';  
                Additional.DownloadFile(c.data,name)
            })
        },
        downloadExcel(){
            OfferApi.DownloadExcelFile(this.request.GroupCode).then(res=>{
                
                    const name = 'Teklif Kıyas.xlsx';  
                    Additional.DownloadFile(res.data,name)
                
            })
        }
    },
    computed:{
        statusColor(){
            if(this.request?.Status == 0) return 'alert-warning'
            else if(this.request?.Status == 1) return 'alert-primary'
            else if(this.request?.Status == 2) return 'alert-danger'
            else if(this.request?.Status == 3) return 'alert-secondary'
            else if(this.request?.Status == 4) return 'alert-success'
        }
    }
}
</script>

<style>

</style>